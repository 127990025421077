import React from 'react';
import styled from 'styled-components';
import Navbar from '../component/Navbar';

// Import the Poppins font
import '@fontsource/poppins';
import Footer from '../component/Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const Section = styled.section`
  margin-bottom: 20px;
  width: 100%;
`;

const Heading = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: #555;
`;

const SubHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #666;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #777;
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  font-size: 1rem;
  line-height: 1.5;
  color: #777;
`;

const Disclaimer = () => {
  return (<>
  
  <Navbar/>
    <Container>
      <Title>DISCLAIMER POLICY</Title>
   
      <Section>
        <Heading>General Information</Heading>
        <Paragraph>he information provided on this website, including text, graphics, images, and other materials, is for informational purposes only. Labridge strives to ensure that the information is accurate and up-to-date; however, we make no warranties or representations, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information, products, services, or related graphics contained on the website for any purpose.
        </Paragraph>
        
      </Section>
      <Section>
        <Heading>Professional Advice</Heading>
        <Paragraph>The content on this website is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on this website.
        </Paragraph>
      </Section>
      <Section>
        <Heading>Limitation of Liability</Heading>
        <Paragraph>In no event will Labridge, its affiliates, or their respective officers, directors, employees, agents, successors, assigns, or content or service providers be liable for any direct, indirect, incidental, consequential, special, exemplary, punitive, or other damages arising out of or in connection with your use of or inability to use the website or any content provided on the website.
        </Paragraph>
      </Section>
      <Section>
        <Heading>
External Links</Heading>
      <Paragraph>Through this website, you are able to link to other websites which are not under the control of Labridge. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
      </Paragraph>
      </Section>
      <Section>
        <Heading>
Changes to the Disclaimer</Heading>
      <Paragraph>Labridge reserves the right to make changes to this Disclaimer at any time. Your continued use of the website following the posting of changes will mean that you accept and agree to the changes.</Paragraph>
      </Section>
    
      </Container>
      <Footer/>
      </>
  )};

  export default Disclaimer;