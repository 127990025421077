import React, { useState } from "react";
import axios from 'axios';
// import { FileUploader } from "react-drag-drop-files";
import LoadingOverlay from 'react-loading-overlay';
// import GoogleMapReact from 'google-map-react';
// import ReactLoading from 'react-loading';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// const fileTypes = ["JPG", "PNG", "GIF","PDF"];
function Becomepartnerform() {
  // const [file, setFile] = useState(null);
  // const [file1, setFile1] = useState(null);
  // const [file2, setFile2] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [cliName, setcliName] = useState("");
  const [mobileNo, setmobileNo] = useState();
  const [address, setaddress] = useState("");
  const [adharcard, setadharcard] = useState("");
  const [pancard, setpandcard] = useState("");
  const [certificate, setcertificate] = useState("");
  const [loads, setloads] = useState(false);
  // const [lab, setlab] = useState(""); 
const [email, setemail] = useState("");
  const [status, setStatus] = useState(null);
  const [pincode, setpincode] = useState();
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [logo, setlogo] = useState("");
  const [pinerr, setpinerr] = useState(false);

  // const AnyReactComponent = ({ text }) => <div>{text}</div>;
  // const defaultProps = {
  //   center: {
  //     lat: 10.99835602,
  //     lng: 77.01502627
  //   },
  //   zoom: 11
  // };


function adharcardupload(e){
  setloads(true);
const file = e.target.files[0];
const bodyFormData = new FormData();
bodyFormData.append('image', file);
axios
.post(`https://api.labridge.in:5011/api/uploadcontent`, bodyFormData)
.then((response) => {
  setadharcard(response.data.details);
  setloads(false);
})
.catch((err) => {
  console.log(err);
 
});

}
function logoupload(e){
  setloads(true);
const file = e.target.files[0];
const bodyFormData = new FormData();
bodyFormData.append('image', file);
axios
.post(`https://api.labridge.in:5011/api/uploadcontent`, bodyFormData)
.then((response) => {
  setlogo(response.data.details);
  setloads(false);
})
.catch((err) => {
  console.log(err);
 
});

}

function pancardupload(e){
  setloads(true);
  const file = e.target.files[0];
  const bodyFormData = new FormData();
  bodyFormData.append('image', file);
  axios
  .post(`https://api.labridge.in:5011/api/uploadcontent`, bodyFormData)
  .then((response) => {
    setpandcard(response.data.details);
    setloads(false);
  })
  .catch((err) => {
    console.log(err);
   
  });

}

function certificateupload(e){
  setloads(true);
  const file = e.target.files[0];
  const bodyFormData = new FormData();
  bodyFormData.append('image', file);
  axios
  .post(`https://api.labridge.in:5011/api/uploadcontent`, bodyFormData)
  .then((response) => {
    setcertificate(response.data.details);
    setloads(false)
  })
  .catch((err) => {
    console.log(err);
   
  });

}

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition((position) => {
        setStatus(null);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      }, () => {
        setStatus('Unable to retrieve your location');
      });
    }
  }

function pincodechange(code){
  const isNumber = /^\d{6}$/.test(Number(code));
  if (!isNumber) {
    toast.error('Invalid Pincode');
  }
  else {
    axios.get(`https://api.labridge.in:5011/apiforsubmission/getcitystate/${code}`).then((data)=>{
      if(data.data.success===true){
        setcity(data.data.data.city);
        setstate(data.data.data.state);
        setpincode(code);
        setpinerr("");
  
      }
      else{
        toast.error('Invalid Pincode');
      }
    })
  }
}

const checkMobileNo = (number) => {
  const re = /^[0-9\b]+$/;
  if (number === '' || re.test(number)) {
    setmobileNo(number);
  } else {
    toast.error('Invalid Phone Number');
  }
}

async function submitdetails(){
if(cliName && mobileNo && email && address && pincode && state && city && adharcard && pancard && logo && certificate){

  await axios
  .post(`https://api.labridge.in:5011/apiforsubmission/submit`, 
  {
    labName: cliName,
    mobile: mobileNo,
    email: email,
    address: address,
    pinCode: pincode,
    state: state,
    city:city,
    adharcard: adharcard,
    pancard: pancard,
    logo:logo,
    certificate: certificate

  })
  .then((response) => {

     if(response.data.success===true){
     alert("Your application is under review")}
    else{
     alert("Something went wrong")
    }
  })
  .catch((err) => {
    console.log(err);
   
  });
}
else{
  alert("All fields are Required")
}

}
  return (
    <div>
      <LoadingOverlay active={loads} spinner text='Uploading...' >
        <Navbar/>
            <div className='become-proccessing-banner'>
              <div className='container'>
                <div className='become-div'>
                        Add Your Pathology With Us
                </div>
                <div className='cotactustxt mt-4'>
                    Connect With LABRIDGE
                </div>
            </div>
            </div>
    
    <div className='container' style={{backgroundColor:"#FFFFF"}}>
      <div className="row mt-5">
        <div className="col-sm-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="form10Example1">Lab Name*</label>
            <input type="text" id="form10Example1" className="form-control" onChange={(e)=>{setcliName(e.target.value)}} required/>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="form10Example1">Pincode*</label>
            <input type="text" id="form10Example1" className="form-control" onBlur={(e)=>{pincodechange(e.target.value);}} required/>
            <p className="warning-text">{pinerr}</p>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="form10Example1">Email*</label>
            <input type="text" id="form10Example1" className="form-control" onChange={(e)=>{setemail(e.target.value)}} required />  
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="form10Example1">Mobile Number*</label>
            <input type="text" id="form10Example1" className="form-control" onChange={(e)=>{checkMobileNo(e.target.value)}} required />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="form10Example1">City*</label>
            <input type="text" id="form10Example1" className="form-control" value={city} disabled required />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="form10Example1">State*</label>
            <input type="text" id="form10Example1" className="form-control" value={state} disabled required/>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-outline">
            <label className="form-label" htmlFor="form10Example1">Address*</label>
            <input type="text" id="form10Example1" className="form-control" onChange={(e)=>{setaddress(e.target.value)}} required />   
          </div>
        </div>
        <div className=' '>
        {/* <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC9Sdyv2Xu3PqtgwqB0sLxOhNPrHjATdq8" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text="My Marker"
        />
      </GoogleMapReact>
    </div> */}
        {/* <button className='btn btn-success mt-4' onClick={getLocation}>Get Location</button> <span>
          {lat && <p>Latitude: {lat}</p>} </span> <span>{lng && <p>Longitude: {lng}</p>}</span> */}
        </div>
      

       
        <div className="mb-3 col-sm-4">
  <label htmlFor="formFile" className="form-label">Upload adharcard*</label>
  <input className="form-control" onChange={adharcardupload} type="file" id="formFile" required />
</div>
<div className="mb-3 col-sm-4">
  <label htmlFor="formFile" className="form-label">Upload pancard*</label>
  <input className="form-control" onChange={pancardupload} type="file" id="formFile" required/>
</div>
<div className="mb-3 col-sm-4">
  <label htmlFor="formFile" className="form-label">Upload certificate*</label>
  <input className="form-control"  onChange={certificateupload} type="file" id="formFile" required/>
</div>
<div className="mb-3 col-sm-4">
  <label htmlFor="formFile" className="form-label">Upload Flex* </label>
  <input className="form-control"  onChange={logoupload} type="file" id="formFile" required/>
</div>
        {/* <div className='d-flex flex-column'>
                  <label className='labeltxt1'>Aadhar Card </label>
                 <FileUploader handleChange={adharcardupload} name="file" types={fileTypes} />
        </div>
        <div className='d-flex flex-column'>
                  <label className='labeltxt1'>Upload Pan Card </label>
                 <FileUploader handleChange={pancardupload} name="file" types={fileTypes} />
        </div>
        <div className='d-flex flex-column'>
                  <label className='labeltxt1'>Lab Processing Certificate </label>
                 <FileUploader handleChange={certificateupload} name="file" types={fileTypes} />
        </div> */}

    

       
       
</div>
<button type="submit" className='send-btn mb-4' onClick={()=>{submitdetails()}}>Submit</button>
    </div>
   <Footer/>
</LoadingOverlay>
<ToastContainer autoClose={2000} theme="colored"/>
    </div>
  )
}

export default Becomepartnerform