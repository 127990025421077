import React from 'react'
import img1 from '../images/1.svg';
import img2 from '../images/2.svg';
import img3 from '../images/3.svg';
import img4 from '../images/4.svg';
function CoreValues() {
  return (
    <div className='container mb-5'>
         <p className='black-text mt-2'>Our<span className='blue-text'> Core Values</span></p>
         <div className='corevalues-div'>
                <div className='core-card' >
                    <img src={img1} />
                    <p >We focus on those <br></br> we Serve</p>
                </div>
                <div className='core-card'>
                <img src={img2} />
                <p >We Strive for excellence <br></br> in all that we do.</p>
                </div>
                <div className='core-card'>
                <img src={img3} />
                <p>We respect all member <br></br> of the team</p>
                </div>
                <div className='core-card'>
                <img src={img4} />
                <p>We continuously seek <br></br> to improve</p>
                </div>
         </div>
    </div>
  )
}

export default CoreValues;