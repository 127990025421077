import React from 'react';
import styled from 'styled-components';
import Navbar from '../component/Navbar';

// Import the Poppins font
import '@fontsource/poppins';
import Footer from '../component/Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const Section = styled.section`
  margin-bottom: 20px;
  width: 100%;
`;

const Heading = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: #555;
`;

const SubHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #666;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #777;
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  font-size: 1rem;
  line-height: 1.5;
  color: #777;
`;

const Privecy = () => {
  return (<>
  
  <Navbar/>
    <Container>
      <Title>PRIVACY POLICY</Title>
      <Section>
       
        <Paragraph>LABRIDGE (A Unit of Healtholife Healthcare Pvt. Ltd.) (“LABRIDGE”) is committed to protecting the privacy of its users (“You,” “Your,” “Yourself”) accessing its website at www.labridge.in (“Website”). This Privacy Policy (“Policy”) explains how LABRIDGE collects, uses, and discloses Your information.</Paragraph>
      </Section>
     
      <Section>
        <Heading>Information and Usage</Heading>
        <Paragraph>LABRIDGE collects both personal and non-personal information. 'Personal Information' includes, but is not limited to, name, address, phone number, date of birth, gender, and email address. This information is gathered when You create an account, make purchases, and communicate with LABRIDGE. We use this information to provide customer support, notify You of updates, and prevent fraud.</Paragraph>
        <Paragraph>Non-personal information collected includes IP addresses, browser type, operating system, and browsing activities. This data helps us understand Website usage and improve our services.</Paragraph>
      </Section>
      <Section>
        <Heading>Security</Heading>
        <Paragraph>LABRIDGE employs standard security measures to protect Your Personal Information from unauthorized access, alteration, disclosure, or destruction. However, no internet site can fully eliminate security risks.</Paragraph>
      </Section>
      <Section>
        <Heading>Third-Party Services</Heading>
        <Paragraph>LABRIDGE may share Your information with third-party service providers (e.g., payment gateways) to facilitate transactions. These providers are obligated to protect Your information. LABRIDGE is not responsible for the privacy practices of these third parties.</Paragraph>
      </Section>
      <Section>
        <Heading>Disclosure</Heading>
      <Paragraph>LABRIDGE may disclose Your information if required by law or to protect our rights and property. We may also share aggregated, non-personal information with partners to enhance our services.</Paragraph>
      </Section>
      <Section>
        <Heading>User Discretion</Heading>
      <Paragraph>You can choose not to provide certain information, but this may limit Your ability to use some Website features. You can update Your Personal Information at any time. If You prefer not to receive marketing communications from us, You can opt-out via the unsubscribe link in our emails.</Paragraph>
      </Section>
      <Section>
        <Heading>General Provisions</Heading>
     <Paragraph>By using the Website, You agree to this Policy. Your information may be transferred and processed in countries where LABRIDGE operates. We may update this Policy from time to time, and Your continued use of the Website constitutes acceptance of these changes.</Paragraph>
      </Section>
      <Section>
        <Heading>Grievance Officer</Heading>
     <Paragraph>For any privacy-related concerns, please contact our Grievance Officer at info@labridge.com</Paragraph>
      </Section>
    
      </Container>
      <Footer/>
      </>
  )};

  export default Privecy;