import React from 'react'
import Whoweare from '../component/Whoweare'
import img1 from '../images/Image_1.png'; 
import img2 from '../images/Image_2.png';
import img3 from '../images/Image_3.png';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
function Whowearemain() {
  return (
    <div>
      <Navbar/>
        <div className='who-hero'>
                About Labridge
        </div>
        <Whoweare/>
        <div className='container'>
    <div className='flex-div'>
        <div className='div-left1 mt-4'>
            <p className='who-p'>Bridge Between</p>
            <p className='who-p2'>Collection Center and Processing Lab</p>
            <p className='who-p3'>Labridge streamlines sample tracking, logistics, and communication between collection centers and processing labs. It ensures efficient transportation, seamless communication, and effective coordination, helping life sciences companies deliver high-quality products while reducing waste and maximizing profits. </p>
        </div>
    <div className='div-right1'><img src={img1} height={400}  /></div>
    </div>
    <div className='flex-div'><div className='div-right1'><img src={img2} height={400}  /></div>
    <div className='div-right2'>  <p className='who-p mt-4'>Book anytime</p>
            <p className='who-p2'>anytime anywhere Lab Test In India</p>
            <p className='who-p3'>Labridge Partner Network provides anytime anywhere blood tests in India through their partner diagnostic centers. They offer a wide range of blood tests, which can be booked online via their website or mobile app. Some tests require fasting, so follow the instructions provided. It's always best to consult with your doctor before getting any blood tests done. </p></div ></div>
    <div className='flex-div'><div className='div-left3 mt-4'>  <p className='who-p'>Buy Personal</p>
            <p  className='who-p2'>Health Product and Lab Equipment</p>
            <p className='who-p3'>We provide wide range of health product and also for out lab partners we also provide any type of lab equipment. </p></div>
    <div className='div-right1'><img src={img3} height={400}  /></div>
    </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Whowearemain