import React, {useState, useEffect} from 'react'
import { useNavigate,useParams} from "react-router-dom";
import Whoweare from "../component/Whoweare";
import GoogleMapReact from 'google-map-react';
import axios from "axios";

function Findnearest() {
    const AnyReactComponent = ({ text }) => <div>{text}</div>;
const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };


useEffect(()=>{
 
},[])
  return (
    <div>
    <div className='container'>
         <div className='add-detail-div mt-4'>
    ← Find Nearest Partner
        </div>
        <div className='order-div mt-4 mb-4'>
            <div className='order-div-head d-flex justify-content-between'>
                <div>Rk diagnostic </div>
                <div>Rkdiagnostic2020@gmail.com | 7738806228</div> 
            </div>
          <div className='d-flex justify-content-between ps-3 pe-3'>
                <p className='test-name mt-2'>Address:Shop no 07 raziya complex shibli nagar mumbra thane  </p>
                <p className='price-p'> </p>
            </div> 
            
        </div>
        <div className='order-div mt-4 mb-4'>
            <div className='order-div-head d-flex justify-content-between'>
                <div>Shree Ganesh Pathology</div>
                <div>shreeganeshpatholab@gmail.com | 9702710166</div> 
            </div>
          <div className='d-flex justify-content-between ps-3 pe-3'>
                <p className='test-name mt-2'>Address:Ss_3,room no 315, sector 7, koparkhairne   </p>
                <p className='price-p'> </p>
            </div> 
            
        </div>
        <div className='order-div mt-4 mb-4'>
            <div className='order-div-head d-flex justify-content-between'>
                <div>Jeevan Jyoti pathology </div>
                <div>jeevanjyotidiagnostic9@gmail.com | 9768651718</div> 
            </div>
          <div className='d-flex justify-content-between ps-3 pe-3'>
                <p className='test-name mt-2'>Address:Shop no 5, Bhuva peri chs Ltd, kharegaon, Kalwa west, Thane 400605  </p>
                <p className='price-p'> </p>
            </div> 
            
        </div>
        <div className='order-div mt-4 mb-4'>
            <div className='order-div-head d-flex justify-content-between'>
                <div> Gurmeet Imaging  </div>
                <div>gurmeetimaging@gmail.com | 9324285863</div> 
            </div>
          <div className='d-flex justify-content-between ps-3 pe-3'>
                <p className='test-name mt-2'>Address:Ground floor bulphoe co-op Hsg.sec 19a plot no 27 </p>
                <p className='price-p'> </p>
            </div> 
            
        </div>

    </div>
    <Whoweare />
    </div>
  )
}

export default Findnearest;