  import React from 'react'
  
  function Ourdiagservice() {
    return (
        <section className='container '>
        <div className="row1">
        <p className='black-text mt-2'>Our<span className='blue-text'> Services</span></p>
        </div>
        <div className="row1">
          <div className="column1">
            <div className="card1">
              <div className="icon-wrapper">
                <i className="fas fa-hammer" />
              </div>
              <h3 className='h31'>Pathology Testing</h3>
              <p className='p1'>
              Our pathology testing services cover a range of tests for diagnosing various medical conditions. We use advanced technology and techniques to provide accurate results for infectious diseases, genetic disorders, autoimmune diseases, cancer, hormonal imbalances, and more. Our services are reliable and help guide treatment decisions for physicians, healthcare facilities, and individuals.
              </p>
            </div>
          </div>
          <div className="column1">
            <div className="card1">
              <div className="icon-wrapper">
                <i className="fas fa-brush" />
              </div>
              <h3 className='h31'>Molecular & Genetic Testing</h3>
              <p className='p1'>
              Our molecular and genetic testing services use advanced techniques to analyze DNA and provide precise information about a patient's genetic makeup. We offer genetic tests for various diseases, including cancer and infectious diseases. Our laboratory is staffed by experienced professionals who use the latest technology to ensure accurate results.
              </p>
            </div>
          </div>
          <div className="column1">
            <div className="card1">
              <div className="icon-wrapper">
                <i className="fas fa-wrench" />
              </div>
              <h3 className='h31'>Corporate & Industrial Health
Check-up support</h3>
              <p className='p1'>
              We offer corporate and industrial health check-up support services to help organizations ensure employee health and well-being. Our services include physical exams, medical screenings, and wellness programs tailored to the organization's needs. We use advanced technology and techniques to provide reliable results and promote a culture of health and wellness in the workplace.
              </p>
            </div>
          </div>
          <div className="column1">
            <div className="card1">
              <div className="icon-wrapper">
                <i className="fas fa-truck-pickup" />
              </div>
              <h3 className='h31'>Technology support to all partners</h3>
              <p className='p1'>
              We provide technology support to all our laboratory partners, including LIMS implementation, instrument maintenance, and data analysis. Our services help optimize laboratory operations and improve efficiency and productivity. We work closely with our partners to ensure seamless integration of our technology solutions into their workflow.
              </p>
            </div>
          </div>
          <div className="column1">
            <div className="card1">
              <div className="icon-wrapper">
                <i className="fas fa-broom" />
              </div>
              <h3 className='h31'>Direct supply of consumable from manufactures</h3>
              <p className='p1'>
              We provide direct supply of consumables from manufacturers to our diagnostics partners, including reagents, test kits, and laboratory supplies. Our services streamline the procurement process and ensure high-quality products at competitive prices. We work closely with manufacturers to provide efficient and reliable supply chain solutions.
              </p>
            </div>
          </div>
          <div className="column1">
            <div className="card1">
              <div className="icon-wrapper">
                <i className="fas fa-plug" />
              </div>
              <h3 className='h31'>Phlebotomist Experts at Your Doorstep</h3>
              <p className='p1'>
              We offer phlebotomist services for specimen collection and processing to all areas as a diagnostics provider. Our highly trained professionals use the latest techniques and equipment for safe and efficient collection. We customize our services to our clients' needs and provide reliable and convenient phlebotomy services.
              </p>
            </div>
          </div>
          <div className="column1">
            <div className="card1">
              <div className="icon-wrapper">
                <i className="fas fa-plug" />
              </div>
              <h3 className='h31'>Affordable and Reliable Diagnostics Support</h3>
              <p className='p1'>
              We offer the best price and support services to our diagnostics partners. Our customized solutions fit our clients' budgets and our experienced team provides reliable technical support. We aim to build long-term relationships with exceptional value and support.
              </p>
            </div>
          </div>
          <div className="column1">
            <div className="card1">
              <div className="icon-wrapper">
                <i className="fas fa-plug" />
              </div>
              <h3 className='h31'>Empowering Collaborative Diagnostics!</h3>
              <p className='p1'>
              We provide diagnostics support to healthcare providers through secure data transfer, remote consultations, and collaborative testing. Our goal is to enhance patient care by fostering efficient and reliable communication among medical professionals.
              </p>
            </div>
          </div>
        </div>
      </section>
      
    )
  }
  
  export default Ourdiagservice