/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import logoImg from '../images/logo.png';

function Navbar() {

  const [log, setlog] = useState(true);
  const [logb2c, setlogb2b] = useState(false);
  function checklogin(){
    if(!localStorage.getItem("userdata")){
      setlog(false);
      if(localStorage.getItem("userdatab2c")){
        setlogb2b(true)
      }
    }
    
  }
  
  

  useEffect(()=>{
     checklogin();
  },[])

  return (
<nav className="navbar navbar-expand-lg p-3 " style={{'boxShadow':"0px 0px 8px rgba(0, 0, 0, 0.1)", 'width':"100vw"}}>
  <div className="container" >
  <Link to="/" > <a className="navbar-brand" href="#"><img width={180} src={logoImg} alt=''/></a></Link> 
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <div className='me-auto'></div>
     <div className="right-nav-div" >
        <div className='nav-item dropdown'>
        <a className="nav-link nav-text dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    Partner
  </a>
  <ul className="dropdown-menu">
  <li><div className='nav-text dropdown-item'>Become Processing Partner</div></li>
   <li> <Link to="/add-partner" ><div className='nav-text dropdown-item'>Add Pathology</div></Link></li>
  </ul>
        </div>
    <Link to="/who-we-are"><div className='nav-text'>Who we are</div></Link>
    <Link to="/ContactUs"><div className='nav-text'>Contact Us</div></Link>
    <a href='https://partner.labridge.in'><div className='nav-text'><i class="fa-solid fa-right-to-bracket"></i>Partner Login</div></a>   
        {/* <Link to="/register" > <div className='nav-text'>Sign up</div></Link> */}
      </div>
    </div>
  </div>
</nav>
  )
}

export default Navbar