
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Becomepartnerform from './pages/Addpathology';
import Cancellation from './pages/Cancellation';
import ContactUs from './pages/ContactUs';
import Disclaimer from './pages/Disclaimer';
import Findnearest from './pages/Findnearest';
import Home from "./pages/Home";
import Privecy from './pages/Privecy';
import RateList from './pages/RateList';
import Refundpolicy from './pages/Refundpolicy';
import Terms from './pages/Terms';
import Whowearemain from './pages/Whowearemain';

ReactGA.initialize('G-62CQ8DRTVR');


function App() {
// Disable right-click
document.addEventListener('contextmenu', (e) => e.preventDefault());

function ctrlShiftKey(e, keyCode) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

document.onkeydown = (e) => {
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U

  if (
  // e.key == 'F12' ||
    ctrlShiftKey(e, 'I') ||
    ctrlShiftKey(e, 'J') ||
    ctrlShiftKey(e, 'C') ||
    (e.ctrlKey && e.key === 'U')
  )
    return false;
};
useEffect(() => {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
}, []);
  return (
    <div className="App">
      
       <Routes>
       <Route path="/" element={<Home/>} />
       <Route path="/add-partner" element={<Becomepartnerform/>} />
       {/* <Route path="/register" element={<Register/>} />
       <Route path="/login" element={<Login1/>} /> */}
       {/* <Route path="/become-processing-partner" element={<Becomeprocess/>} />
       <Route path="/book-test-online" element={<BookOnline/>} /> */}
       {/* <Route path="/test/:id" element={<ViewTest/>} />
        <Route path='/life-style-product' element={<Lifestyleproduct/>}/>      
        <Route path='/add-patient' element={<PatientDetails/>}/>
        <Route path='/lab' element={<Homelab/>}/>
        <Route path='/book-test' element={<BooktestLab/>}/> */}
        {/* <Route path='/order-history' element={<Orderlab/>}/> */}
        <Route path ='/who-we-are' element={<Whowearemain/>}/>
        {/* <Route path = '/lab/book-test-everywhere' element={<Phleboservices/>}/> */}
        <Route path = '/find-nearest-lab' element={<Findnearest/>}/>
        {/* <Route path="/wallet" element={<Wallet/>}/> */}
        <Route path="/ratelist" element={<RateList/>} />
        <Route path="/terms-and-condition" element={<Terms/>} />
        <Route path="/policy" element={<Privecy/>} />
        <Route path="/ContactUs" element={<ContactUs/>} />
        <Route path="/refund-policy" element={<Refundpolicy/>}/>
        <Route path="/disclaimer" element={<Disclaimer/>}/>
        <Route path="/cancellation-policy" element={<Cancellation/>}/>
        {/* <Route path="/billing-form" element={<BillingForm/>} />
        <Route path='/invoice' element={<Invoice/>} /> */}
       </Routes>
      
    </div>
  );
}

export default App;
