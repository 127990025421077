import React from 'react'
import mobilepng from '../images/mapp.png'
import playstore from '../images/toppng 2.png'
import applestore from '../images/PngItem_1144050 3.png'
function Mobileapp() {
  return (
    <div className='mobilemain-div'>
        <div className='container mobileappflex-div'>
            <div className='mobile-left p-2'>
            <p className='our-div'>Mobile <span className='service-div'>app coming soon..</span></p>
            <p className='mobile-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra nunc ante velit vitae. Est tellus vitae, nullam lobortis enim. Faucibus amet etiam tincidunt rhoncus, ullamcorper velit. Ullamcorper risus tempor, ac nunc libero urna, feugiat.</p>
            <div className='d-flex'>
                <img src={playstore} />
                <img src={applestore}/>
            </div>
            </div>
            <div className='mobile-right p-4' >
            <img className='img-tag' src={mobilepng} />
            </div>
        </div>
    </div>
  )
}

export default Mobileapp; 