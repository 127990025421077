import React from 'react';
import img1 from '../images/Navratri_Slide 2.png';

function Top() {
  return (
    <div className=''>
      <div className=' w-100' >
        {/* <div className='header-left-divs'>
          <div className='left-div-child'>
            <div className='p-4'>
            <p className='welcome-p'>Welcome to</p>
            <p className='labridge-p'>Lab<span className='labridge-color'>ridge</span></p>
            <p className='onestop-div'>One stop for all your medical needs.</p>
            <div className='top_section_input_container'> 
      <div class="dropdown-center">
  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Location
  </button>
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">Mumbai</a></li>
    <li><a class="dropdown-item" href="#">Navi Mumbai</a></li>
   
  </ul>
</div>
      <input className='input_container_search' typeof='text' placeholder='Search For Labs, Tests or Products ' />
      

      </div>
            </div>
          </div>
        </div> */}
      <div
  id="carouselExampleAutoplaying"
  className="carousel slide"
  data-bs-ride="carousel"
>
  <div className="carousel-inner">
     
    <div className="carousel-item active">
      <img src={img1} style={{height:"80vh"}} className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
      <img src={img1} style={{height:"80vh"}} className="d-block w-100" alt="..." />
    </div>
  </div>
  <button
    className="carousel-control-prev"
    type="button"
    data-bs-target="#carouselExampleAutoplaying"
    data-bs-slide="prev"
  >
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next"
    type="button"
    data-bs-target="#carouselExampleAutoplaying"
    data-bs-slide="next"
  >
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

</div>
      </div>
   
  )
}

export default Top