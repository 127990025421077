import React from 'react'
import icon1 from '../images/Icon1.svg';
import icon2 from '../images/Icon_2.png';
import icon3 from '../images/Icon_3.png';
function Marketplace() {
  return (
    <div className='market-div' style={{marginTop: '15px'}}>
        <div className='container'>
            <p className='market-p'>Labridge - Pathology Ka Marketplace</p>
            <div className='market-icon'>
            <div><img  src={icon1} height={100}/><p className='market-ps mt-1'>All testing only under one roof</p></div>
                <div><img src={icon2} height={100}/><p className='market-ps mt-1'>Access multiple labs without <br></br>using multiple apps and portal</p></div>
               <div><img src={icon3} height={100}/><p className='market-ps mt-1'>360 degree pathology solution</p></div>
            </div>
        </div>
    </div>
  )
}

export default Marketplace