import React from 'react'
import rightimg from '../images/mask-group@2x.png';
import Top from '../component/Top';
import Searchpart from '../component/Searchpart';
import Ourservice from '../component/Ourservice';
import Whoweare from '../component/Whoweare';
import Lifestyle from "../component/Lifestyle";
import Contactus from '../component/Contactus';
import Mobileapp from '../component/Mobileapp';
import Ourmission from '../component/Ourmission';
import Marketplace from "../component/Marketplace";
import CoreValues from '../component/CoreValues';
import Ourdiagservice from '../component/Ourdiagservice';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';

function Home() {
  return (
    <div>
      <Navbar/>
        <Top/>
        <Ourservice/>
        <Marketplace/>
        <Ourmission/>
        <CoreValues/>
        <Whoweare/>
        {/* <Contactus/> */}
        <Ourdiagservice/>
        <Footer/>
        
       
    </div>
  )
}

export default Home;