import React from 'react'
import { Link } from "react-router-dom";
import Servicecards from './Servicecards';
function Ourservice() {
  return (
    <div className='ourservice-div pt-5 pb-5'>
       <center>
            <div className='top_section_input_container'> 
      <div class="dropdown-center">
  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Location
  </button>
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">Mumbai</a></li>
    <li><a class="dropdown-item" href="#">Navi Mumbai</a></li>
   
  </ul>
</div>
      <input className='input_container_search' typeof='text' placeholder='Search For Labs, Tests or Products ' />
      

      </div>
      </center>
        <div className='container contedit'>
            <p className='our-div'>Our <span className='service-div'>Service →</span></p>
            <div className='service-card-maindiv row'>
              <div className='p-2 col-12 col-md-6 col-lg-4 '>  
              <div className='property-card1  ' >
              <Link to="">   BooK Test Online </Link>
                    </div>
                    </div>
             <div className='p-2 col-12 col-md-6 col-lg-4'>
                    <div className='property-card2 '>
                    <Link to="/find-nearest-lab">  Find Nearest Partner</Link>
                    </div>
                    </div>
                    <div className='p-2  col-12 col-md-6 col-lg-4'>
                  <div className='property-card'>
                  <Link to="">  Lifestyle Products</Link>
                    </div>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default Ourservice;