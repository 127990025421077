import React from 'react'
import imgs from '../images/44126.jpg'
function Ourmission() {
  return (
    <div className='ourmission-div '>
      <center>  <div>
        <p className='black-text mt-2'>Our<span className='blue-text'> Mission and Vision</span></p>
        </div>
        </center>
        <div className='missiondiv'>
        <div>
            <img src={imgs} height={500} />
        </div>
        <div className='cards-divmis' >
        <div className="card addedstylecard m-4  " style={{ maxWidth: "18rem" }}>

  <div className="card-body d-flex flex-column justify-content-center align-items-center">
   <center><h5 className="card-title">Our Mission</h5></center> 
    <p className="card-text">
    Labridge is on a mission to empower diagnostic players by enabling them to deliver the highest quality services at the most competitive prices and within the optimal turnaround times (TAT).
    </p>
  </div>
</div>
<div className="card addedstylecard vission-card m-4 " style={{ maxWidth: "18rem" }}>

<div className="card-body d-flex flex-column justify-content-center align-items-center">
    <center><h5 className="card-title">Our Vision</h5></center>
    <p className="card-text">
    At Labridge, our vision is to bridge the diagnostic ecosystem by becoming the most preferred and trusted business partner for all pathology testing services.
    </p>
  </div>
</div>
        </div>
        </div>
       
    </div>
  )
}

export default Ourmission