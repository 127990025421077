import React from 'react';
import styled from 'styled-components';
import Navbar from '../component/Navbar';

// Import the Poppins font
import '@fontsource/poppins';
import Footer from '../component/Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const Section = styled.section`
  margin-bottom: 20px;
  width: 100%;
`;

const Heading = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: #555;
`;

const SubHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #666;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #777;
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  font-size: 1rem;
  line-height: 1.5;
  color: #777;
`;

const Refundpolicy = () => {
  return (<>
  
  <Navbar/>
    <Container>
      <Title>REFUND AND PAYMENT POLICY</Title>
      <Section>
        <Heading>Refund/Credit Note/Reversal Claims</Heading>
        <Paragraph>To claim a refund, credit note, or reversal for tests that are not performed, cancelled, or involve excess or incorrect billing, please submit the relevant documents and reports within one week of sample registration.</Paragraph>
      </Section>
      <Section>
        <Heading>Payment Gateway Refunds:</Heading>
        <Paragraph>If the payment was made through a payment gateway, the refund amount will be credited according to the payment gateway's terms and conditions, following verification of the records and documents.</Paragraph>
      </Section>
      <Section>
        <Heading>Third-Party Payment Mechanisms</Heading>
      <Paragraph>Users/customers acknowledge and agree that payment mechanisms may be governed by separate agreements between the third parties providing these facilities and the Company.</Paragraph>
      </Section>
      <Section>
        <Heading>Liability for Payment Procedures</Heading>
      <Paragraph>Users/customers understand and agree that the Company takes no responsibility or liability for any malfunction or defect in any payment procedure. The payment of the price is the sole responsibility of the user/customer.</Paragraph>
      </Section>
      <Section>
        <Heading>Commencement of Services</Heading>
     <Paragraph>Users/customers acknowledge and agree that services will only commence once the payment is realized in the Company's accounts if online payment is opted for.</Paragraph>
      </Section>
      </Container>
      <Footer/>
      </>
  )};

  export default Refundpolicy;