import '@fontsource/poppins';
import React from 'react';
import styled from 'styled-components';
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #3c9bd6;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  background: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Heading = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: #3c9bd6;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Address = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
`;

const ContactInfo = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const ContactLink = styled.a`
  color: #3c9bd6;
  text-decoration: none;
  font-weight: bold;
`;

const ContactUs = () => {
  return (<>
  <Navbar/>
  
    <Container>
      <Title>Contact Us</Title>
      <Section>
        <Heading>Branch/Location Addresses</Heading>
        <Address>
          <strong>BRL, Thane</strong><br />
          Star Ankur Building, 1st Floor, Near Fatima Church, Majiwada, Thane (W) - 400601.
        </Address>
        <Address>
          <strong>BRL, Nalasopara</strong><br />
          Unit No.G-10, Sayukta Nagar, Nalasopara (E), Palghar - 401209.
        </Address>
        <Address>
          <strong>BRL, Akola</strong><br />
          House No. 5, Giri Nagar, Near Gaurakshan Road, Akola - 444004.
        </Address>
        <Address>
          <strong>Zipatho, Lucknow</strong><br />
          3/35, Vikalp Khand - 3, Gomtinagar, Near Rail Vihar Yojna, Lucknow - 226010.
        </Address>
        <Address>
          <strong>Healtholife Healthcare Head Office</strong><br />
          1069, U Wing, 1st Floor, Akshar Business Park, Sector 25, Vashi, Navi Mumbai - 400703.
        </Address>
      </Section>
      <ContactInfo>
        <p>Phone: <ContactLink href="tel:+919324124815">+91 93241 24815</ContactLink></p>
        <p>Email: <ContactLink emailTo = "info@labridge.in">info@labridge.in</ContactLink></p>
      </ContactInfo>
    </Container>
    <Footer/>
    </>
  );
};

export default ContactUs;
