import axios from 'axios';
import React, {useCallback, useEffect, useState} from 'react';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import rightArrow from "../images/arrow-right-solid.svg";
import leftArrow from "../images/arrow-left-solid.svg";
export default function RateList() {

  const [labNames, setLabNames] = useState([]);
  const [selectedLab, setSelectedLab] = useState('');
  const [rateList, setRateList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredRateList, setFilteredRateList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(20);

  useEffect(() => {
    // Fetch lab names from the API
    fetchLabNames();
  }, []);

  const filterRateList = useCallback(() => {
    const lowercaseSearchTerm = searchTerm.toLowerCase();
    const filteredList = rateList.filter((rate) =>
      rate && rate.TestName && rate.TestName.toLowerCase().includes(lowercaseSearchTerm)
    );
    setFilteredRateList(filteredList);
  }, [rateList, searchTerm]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        filterRateList();
      } else {
        setFilteredRateList(rateList);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [rateList, searchTerm, filterRateList]);

  // useEffect(() => {
  //   if (searchTerm) {
  //       console.log(rateList);
  //       debugger;
  //       setFilteredRateList(rateList.filter((rate) =>
  //         rate.TestName.toLowerCase().includes(searchTerm.toLowerCase())
  //       ));
  //     } else {
  //       setFilteredRateList(rateList);
  //     }
  // }, [rateList, searchTerm]);

  const totalPages = Math.ceil(filteredRateList.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentRows = filteredRateList.slice(startIndex, endIndex);

  const fetchLabNames = async () => {
    try {
      const result =  await axios.get("https://api.labridge.in:5011/bookTestB2b/labName") ;
      const data = result.data;
      setLabNames(data);
    } catch (error) {
      console.error('Error fetching lab names:', error);
    }
  };
  
  const handleLabChange = async (event) => {
    const selectedLabName = event.target.value;
    setSelectedLab(selectedLabName);
    // setSelectedLabName(selectedLabName.labName);

    // Fetch rateList for the selected lab
    try {
      // setRateList([]);
      const response = await axios.get(`https://api.labridge.in:5011/rateList/${selectedLabName}`); // Replace with your API endpoint for fetching rateList
      setRateList(response.data.data);
    } catch (error) {
      console.error(`Error fetching rateList for lab ${selectedLabName}:`, error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
  

  const handlePageChange = page => {
    if (page > 0 && page <= totalPages) {
        setCurrentPage(page);
    }
  };

  return (
    <div>
        <Navbar/>
        <div className='container'>
            <div className='mt-20' style={{ marginTop: '40px !important' }}>
            <h1>Rate List Page</h1>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between",marginBottom: '20px' }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span >Select Lab:</span>
                    <select value={selectedLab} onChange={handleLabChange} style={{ marginLeft: '15px' }}>
                        <option value="">Select Lab</option>
                        {labNames.map((labName) => (
                        <option key={labName.labCode} value={labName.labCode}>
                            {labName.labName}
                        </option>
                        ))}
                    </select>
                </div>
                <input type="text" value={searchTerm} onChange={handleSearch} placeholder="Search by test name" />
            </div>
                {selectedLab && (
                    <>
                        <div>
                            {/* <h2>Rate List for {selectedLabName}</h2> */}
                            {filteredRateList.length === 0 ? (
                                <p>No rateList available for the selected lab.</p>
                            ) : (
                                <>
                                    <div className='row tableHeader'>
                                        <div className='col-sm-2'>Test Code</div>
                                        <div className='col-sm-6'>Test Name</div>
                                        <div className='col-sm-2'>Category</div>
                                        <div className='col-sm-1'>MRP</div>
                                        <div className='col-sm-1'>B2B Price</div>
                                    </div>
                                    {currentRows.map((rate, index) => (
                                        <div key={index} className={index % 2 === 0 ? 'even row tableRow' : 'odd row tableRow'}>
                                            <div className='col-sm-2'>{rate.TestCode}</div>
                                            <div className='col-sm-6'>{rate.TestName}</div>
                                            <div className='col-sm-2'>{rate.Category}</div>
                                            <div className='col-sm-1'>{rate.MRP}</div>
                                            <div className='col-sm-1'>{rate.B2BPrice}</div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        <div className='row pagination'>
                              {/* ... previous page button ... */}
                              <div className='col-sm-11'>Showing { currentPage } page of { totalPages } pages</div>
                              <div className='col-sm-1'>
                                <img
                                    style={{height: '35px',padding: '5px',border: '1px solid #EEEEEE'}}
                                    src={leftArrow}
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    alt=''
                                />
                                {/* ... next page button ... */}
                                <img
                                    style={{height: '35px',padding: '5px',border: '1px solid #EEEEEE', marginLeft: '10px'}}
                                    src={rightArrow}
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    alt=''
                                />
                              </div>
                        </div>
                    </>

                )}
            </div>
        </div>
        <Footer/>    
    </div>
  )
}
