import React from 'react';
import styled from 'styled-components';
import Navbar from '../component/Navbar';

// Import the Poppins font
import '@fontsource/poppins';
import Footer from '../component/Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const Section = styled.section`
  margin-bottom: 20px;
  width: 100%;
`;

const Heading = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: #555;
`;

const SubHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #666;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #777;
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  font-size: 1rem;
  line-height: 1.5;
  color: #777;
`;

const Terms = () => {
  return (<>
  
  <Navbar/>
    <Container>
      <Title>Terms and Conditions</Title>
      <Section>
       
        <Paragraph>These Terms of Use ("Terms") govern your use of the website www.labridge.in ("Website") owned by LABRIDGE INTERNATIONAL PVT. LTD., a unit of HEALTHOLIFE HEALTHCARE PRIVATE LIMITED, located at Flat No. 207, Janimal Towers, Plot No. 42, Sector-17, Vashi, Navi Mumbai, Thane, Maharashtra-400703, India.</Paragraph>
      </Section>
      <Section>
        <Heading>General</Heading>
        <Paragraph>By using the Website or any service provided through it, you agree to these Terms and of the Privacy Policy.</Paragraph>
        <Paragraph>LABRIDGE may change these Terms at any time without prior notice. Your continued use of the Website signifies your acceptance of the updated Terms.</Paragraph>
        <Paragraph>LABRIDGE delivers services only within India as per the Order Confirmation Form.</Paragraph>
      </Section>
      <Section>
        <Heading>Services</Heading>
        <Paragraph>The Website facilitates the online services offered by LABRIDGE.</Paragraph>
      </Section>
      <Section>
        <Heading>Eligibility</Heading>
        <List>
          <ListItem>Services are available only to users over 18 years old.</ListItem>
          <ListItem>LABRIDGE may refuse or terminate access to the Services at its discretion.</ListItem>
          <ListItem>Users cannot have multiple accounts and must not transfer their account to others.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>User Account, Password, and Security</Heading>
        <List>
          <ListItem>To use the Services, you must register and create an account. You are responsible for maintaining the confidentiality of your Account Information and all activities under your account. Notify LABRIDGE immediately of any unauthorized use.</ListItem>
          <ListItem>You must provide accurate, complete, and up-to-date information. Inaccurate information may lead to suspension or termination of your account.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>Pricing Information</Heading>
        <List>
          <ListItem>LABRIDGE aims to offer the best prices for products and services. Pricing details are available under the Terms of Offer For Sale.</ListItem>
          <ListItem>You must use your own credit/debit card for transactions. LABRIDGE is not liable for unauthorized use of your card.</ListItem>
          <ListItem>LABRIDGE is not liable for any loss or damage arising from the decline of transaction authorization.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>User Obligations</Heading>
        <List>
          <ListItem>Access and use the Website and Services as per the Terms of Use.</ListItem>
          <ListItem>Use Services and materials only for permitted purposes under applicable law.</ListItem>
          <ListItem>Follow limitations on the dissemination, use, and reproduction of materials.</ListItem>
          <ListItem>Access the Website only through LABRIDGE’s provided interface; avoid unauthorized methods.</ListItem>
          <ListItem>You are responsible for any breach of the obligations and resulting consequences.</ListItem>
          <ListItem>LABRIDGE is a facilitator, not the seller, of products and services.</ListItem>
          <ListItem>LABRIDGE may modify or discontinue parts of the Website at any time.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>Use of Materials</Heading>
        <List>
          <ListItem>Access, download, and print materials for personal use only, without modification or resale.</ListItem>
          <ListItem>Website design and layout are protected by intellectual property rights.</ListItem>
          <ListItem>Software on the Website is the property of LABRIDGE or its vendors.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>Usage Conduct</Heading>
        <List>
          <ListItem>Maintain necessary computer equipment and Internet connections for Website access.</ListItem>
          <ListItem>Use the Website reasonably and lawfully.</ListItem>
          <ListItem>LABRIDGE is not liable for declined transactions due to preset limits with acquiring banks.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>Intellectual Property Rights</Heading>
        <List>
          <ListItem>Website Content is owned by LABRIDGE and protected by intellectual property laws.</ListItem>
          <ListItem>Trademarks on the Website belong to LABRIDGE or third parties; unauthorized use is prohibited.</ListItem>
          <ListItem>LABRIDGE owns all intellectual property rights to its trademarks and Website.</ListItem>
          <ListItem>Do not copy, republish, or distribute Website Content without authorization.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>Disclaimer of Warranties & Liability</Heading>
        <List>
          <ListItem>LABRIDGE is not liable for issues related to user content or any misuse of your account.</ListItem>
          <ListItem>LABRIDGE is not responsible for unauthorized use of your account or any damages resulting from using the Website. We are also not liable for any errors or downtime related to the Website.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>Indemnification and Limitation of Liability</Heading>
        <List>
          <ListItem>You agree to indemnify LABRIDGE from any losses or claims related to your breach of these Terms.</ListItem>
          <ListItem>LABRIDGE’s liability is limited to refunding any specific charges related to the disputed service or product.</ListItem>
          <ListItem>LABRIDGE is not liable for special, incidental, or consequential damages arising from your use of the Website.</ListItem>
          <ListItem>These limitations apply to the maximum extent permitted by law.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>Violation of the Terms of Use</Heading>
        <List>
          <ListItem>LABRIDGE may terminate your access if you violate these Terms. We may also seek injunctive relief for such violations.</ListItem>
          <ListItem>LABRIDGE may terminate your access for various reasons including legal requirements or technical issues.</ListItem>
          <ListItem>If LABRIDGE takes legal action against you, you must cover all related costs.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>Termination</Heading>
        <List>
          <ListItem>You can terminate your agreement by not accessing the Website or closing your accounts.</ListItem>
          <ListItem>LABRIDGE may terminate the Terms for reasons including breaches or commercial viability.</ListItem>
          <ListItem>Termination may involve removing your access, materials, and account information.</ListItem>
          <ListItem>LABRIDGE is not liable for any consequences of account termination.</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>Governing Law</Heading>
        <Paragraph>These Terms are governed by Indian law, and disputes will be resolved in the courts at Navi Mumbai, India.</Paragraph>
      </Section>
      <Section>
        <Heading>Report Abuse</Heading>
        <Paragraph>Report any abuse or objectionable content to LABRIDGE customer support.</Paragraph>
      </Section>
      <Section>
        <Heading>Privacy Policy</Heading>
        <Paragraph>You agree to LABRIDGE’s Privacy Policy regarding the Website.</Paragraph>
      </Section>
      <Section>
        <Heading>Communications</Heading>
        <Paragraph>You consent to receiving communications from LABRIDGE via SMS or email.</Paragraph>
      </Section>
      <Section>
        <Heading>General Provisions</Heading>
        <List>
          <ListItem>Notices from LABRIDGE will be sent via email or posted on the Website. Notices to LABRIDGE should go to customer support.</ListItem>
          <ListItem>You cannot transfer these Terms, but LABRIDGE can transfer its rights.</ListItem>
          <ListItem>If a provision is unenforceable, the rest of the Terms remain in effect.</ListItem>
          <ListItem>Failure to enforce any provision does.</ListItem>
          </List>
    </Section>
    <Section>
        <Heading>Feedback and Information</Heading>
        <Paragraph>Any feedback you provide is non-confidential and can be used by LABRIDGE without compensation to you.</Paragraph>
      </Section>
      <Section>
        <Heading>Changes to Terms</Heading>
        <Paragraph>LABRIDGE may change the Terms at any time without notice.
We reserve the right to disqualify customers involved in fraudulent activities.</Paragraph>
      </Section>
      </Container>
      <Footer/>
      </>
  )};

  export default Terms;