import React from 'react';
import styled from 'styled-components';
import Navbar from '../component/Navbar';

// Import the Poppins font
import '@fontsource/poppins';
import Footer from '../component/Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const Section = styled.section`
  margin-bottom: 20px;
  width: 100%;
`;

const Heading = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: #555;
`;

const SubHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #666;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #777;
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  font-size: 1rem;
  line-height: 1.5;
  color: #777;
`;

const Cancellation = () => {
  return (<>
  
  <Navbar/>
    <Container>
      <Title>Cancellation Policy</Title>
   <Paragraph>Test cancellations are subject to the following conditions:
   </Paragraph>
      <Section>
        <Heading>Before Accession
        </Heading>
        <Paragraph>Cancellations are permitted before the accession process begins. If you wish to cancel a test order, please contact our customer service team immediately to prevent the test from being accessioned.

        </Paragraph>
        
      </Section>
      <Section>
        <Heading>Quality of Sample Received
        </Heading>
        <Paragraph>If the quality of the sample received does not meet our standards for testing, the test may be canceled. This includes samples that are contaminated, insufficient, or improperly collected. Our team will notify you promptly if your sample does not meet the required quality standards.
        </Paragraph>
      </Section>
      <Section>
        <Heading>After Accession and Before Processing
        </Heading>
        <Paragraph>Cancellations after the accession process but before the processing stage are possible under specific circumstances. If a valid reason for cancellation is provided, our team will review the request and determine if it can be accommodated. Valid reasons may include but are not limited to, incorrect test orders, change in medical conditions, or other justifiable situations.

        </Paragraph>
      </Section>
      <Section>
        <Heading>
        Damaged or Expired Samples
        </Heading>
      <Paragraph>Tests will be canceled if the samples received are found to be damaged or expired. Our team will conduct a thorough inspection of the samples upon receipt and notify you if the samples are unsuitable for testing due to damage or expiration.

      </Paragraph>
      </Section>
      <Section>
        <Heading>
        Incomplete Details
        </Heading>
      <Paragraph>Incomplete or incorrect information accompanying the samples may lead to test cancellation. It is crucial to provide complete and accurate details to ensure the proper processing of your tests. If any required information is missing or incorrect, we will contact you to rectify the issue. If the issue cannot be resolved, the test will be canceled.</Paragraph>
      </Section>
    
      </Container>
      <Footer/>
      </>
  )};

  export default Cancellation;